@keyframes goUp {
    0% {
        transform: translateY(5rem);
        opacity: 0;
    }

    100% {
        transform: translateY(0rem);
        opacity: 1;
    }
}

.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background: #ffffff;
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.05);
    overflow: hidden;
}

.nav img {
    height: 5rem;
}

.buttonsWrap {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

@media (max-width:800px) {
    .nav img {
        width: 60vw;
        max-width: 15rem;
        height: unset;
    }

    .nav {
        flex-direction: column;
        gap: 1rem;
    }
}