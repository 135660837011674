@keyframes scaleFull {
    0% {
        opacity: 0;
        transform: translateY(2rem);
    }

    100% {
        opacity: 1;
        transform: translateY(0rem);
    }
}

.card {
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.1);
    padding: 1rem;
    width: 20rem;
    min-width: 20rem;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    opacity: 0;
    transform: translateY(2rem);
}

.card:nth-of-type(1) {
    animation: scaleFull forwards 1s 0s;
}

.card:nth-of-type(2) {
    animation: scaleFull forwards 1s 0.3s;
}

.card:nth-of-type(3) {
    animation: scaleFull forwards 1s 0.6s;
}

.imgWrap {
    border-radius: 1rem 1rem 0 0;
    overflow: hidden;
    height: 14rem;
    position: relative;
}

.imgWrap::before {
    content: "";
    display: block;
    position: absolute;
    background: #37b74a;
    right: 0;
    width: 100%;
    height: 1rem;
    bottom: 0;
    left: 0;
}

.imgWrap img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.name {
    font-weight: bold;
    margin-top: 1rem;
    font-size: 1.1rem;
    margin-bottom: 1rem;
}

.icon {
    height: 1rem;
}

.item {
    display: flex;
    /* align-items: center; */
    gap: 0.3rem;
    font-size: 0.9rem;
    opacity: 0.5;
    margin-top: 0.3rem;
}

.item > span:nth-of-type(1) {
    font-weight: bold;
}

.btn {
    text-align: center;
    margin-top: 1rem;
}

@media (max-width: 585px) {
    .card {
        width: 85vw;
        min-width: 85vw;
    }
}

.dietType {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
