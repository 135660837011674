@keyframes goUp {
    0% {
        transform: translateY(5rem);
        opacity: 0;
    }

    100% {
        transform: translateY(0rem);
        opacity: 1;
    }
}

.headerSection {
    height: 80vh;
    position: relative;
    background-color: #111E26;
    background-image: url(../../assets/headerbg.jpg);
    background-position: center center;
    background-size: cover;
    z-index: -2;
}

.overlay {
    background-color: #111E26;
    opacity: 0.96;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    box-sizing: border-box;
}

.bottomShape {
    transform: rotate(180deg);
    bottom: -1px;
    overflow: hidden;
    position: absolute;
    left: 0;
    width: 100%;
    line-height: 0;
    direction: ltr;
    box-sizing: border-box;
}

.bottomShape svg {
    width: calc(100% + 1.3px);
    height: 7rem;
    z-index: -1;
    display: block;
    position: relative;
    fill: white;
}

.headerBelow {
    z-index: 1;
    margin-top: -250px;
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
}

.headerText {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100% - 240px);
    color: white;
    gap: 1rem;
    text-align: center;
    padding: 0 1.5rem;
}

.headerText>div:nth-of-type(1) {
    font-weight: bold;
    font-size: 2.75rem;
}

.headerText>div:nth-of-type(2) {
    opacity: 0.75;
    max-width: 40rem;
    line-height: 1.5rem;
}

.recipesCards {
    display: flex;
    gap: 2rem;
    justify-content: center;
    margin-bottom: 2rem;
    flex-wrap: wrap;
    padding: 0 2rem;
}

@media (max-width:585px) {
    .recipesCards {
        gap: 1rem;
    }
}