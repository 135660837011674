html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    box-sizing: border-box;
    max-width: 100vw;
    overflow-x: hidden;
    font-family: 'Rubik', sans-serif;
}

::-webkit-scrollbar {
    width: 15px;
}

::-webkit-scrollbar-track {
    background: #6d7e70;
}

::-webkit-scrollbar-thumb {
    background: #37B74A;
}

a {
    color: inherit;
    text-decoration: inherit;
}